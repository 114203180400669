import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface Service {
  id: string;
  name: string;
  disabled?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ServiceProvidedService {

  getServices(term: string = null): Observable<Service[]> {
    let services = getServices();
    if (term) {
      services = services.filter(x => x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    }
    return of(services).pipe();
  }
}

function getServices() {
  return [{
    id: 'accounting_consultant',
    name: 'CustomBooks consultation',
    disabled: false
  }, {
    id: 'accountingsetup',
    name: 'CustomBooks setup',
    disabled: false
  }, {
    id: 'audit_review',
    name: 'Audits / reviews',
    disabled: false
  }, {
    id: 'bookkeeping',
    name: 'Bookkeeping',
    disabled: false
  }, {
    id: 'financialplanning_investsment',
    name: 'Financial planning/investments',
    disabled: false
  }, {
    id: 'fixed_audit_review',
    name: 'Fixed asset management',
    disabled: false
  }, {
    id: 'incometax',
    name: 'Income tax preparation/planning',
    disabled: false
  }, {
    id: 'network_support',
    name: 'Network support',
    disabled: false
  }, {
    id: 'payroll',
    name: 'Payroll',
    disabled: false
  }, {
    id: 'writeup',
    name: 'Write-up',
    disabled: false
  }];
}
