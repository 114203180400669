<div class="home-body">
    <app-accountants-map></app-accountants-map>
    <div class="accountants-container">
        <div class="accountants-title-container">
            <div class="title-box">
                <h2 class="title">Find an {{ companyName }} ROCKSTAR</h2>
            </div>
        </div>
        <div class="accountants-filter-container">
            <div class="container">
                <div class="row">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <ng-select [items]="states"
                                    bindLabel="state"
                                    [loading]="statesLoading"
                                    [searchFn]="searchState"
                                    bindValue="state"
                                    placeholder="Location"
                                    [(ngModel)]="selectedState">
                                    <ng-template ng-option-tmp let-item="item">
                                        {{item.state}} <br/>
                                        <small>{{item.abbreviation}}</small>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-md-3 mb-3">
                                <ng-select
                                    [items]="services"
                                    [multiple]="true"
                                    bindLabel="name"
                                    [selectableGroupAsModel]="false"
                                    [closeOnSelect]="false"
                                    placeholder="Service Provided"
                                    bindValue="id"
                                    [(ngModel)]="selectedService">
                                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-md-3 mb-3">
                                <ng-select
                                    [items]="industries"
                                    [multiple]="true"
                                    bindLabel="name"
                                    [selectableGroupAsModel]="false"
                                    [closeOnSelect]="false"
                                    placeholder="Industry Served"
                                    bindValue="id"
                                    [(ngModel)]="selectedIndustry">
                                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-md-3 mb-3">
                                <a class="btn btn-block default-btn" (click)="addFilter()">Search</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accountants-filter-info">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="input-group">
                            <input type="text" [(ngModel)]="searchAccountant" (keyup)="onSearch($event)" class="form-control" placeholder="Search (Company, Name)">
                        </div>
                    </div>
                    <div class="col-md-8 text-right">
                        <small class="mr-2">Accountants per page</small>
                        <select name="filters" class="tag mr-4" [(ngModel)]="itemsPerPage" (change)="changePerPageOption()">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col accountants-filter-result">
            <div class="container">
                <div class="row">
                    <div class="col-md-9">
                        <div class="accountants-list-container">
                            <div class="accountant-box" *ngFor="let accountant of accountants | paginate: { itemsPerPage: this.itemsPerPage, currentPage: this.currentPage, totalItems: this.totalAccountants }">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <h5 class="card-title text-capitalize"><a class="card-link" [routerLink]="['/profile', accountant.rockStarProfileUrlName]" [queryParams]="{search: searchAccountant}">{{ accountant.fullName }}</a>
                                                    <span class="card-subtitle text-capitalize">&nbsp; {{ accountant.jobTitle }}</span>
                                                </h5>
                                                <div class="rating" *ngIf="accountant.noOfReviews != 0">
                                                    {{ accountant.avgOfReviews }} &nbsp;<bar-rating [readOnly]="true" [(rate)]="accountant.avgOfReviews" [max]="5"></bar-rating> {{accountant.noOfReviews}} review(s)
                                                </div>
                                                <p class="company text-capitalize">{{ accountant.company }}</p>
                                            </div>
                                            <div class="col-md-6 text-md-right address">
                                                <p>{{ accountant.address }}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <ng-container *ngIf="accountant?.company_logo; else appCompanyImage">
                                                    <div class="app-company-logo-wrapper">
                                                        <div class="app-company-logo">
                                                            <img class="app-company-logo-img" src="{{ accountant?.company_logo }}" alt="">
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-template #appCompanyImage>
                                                    <div class="app-company-logo-wrapper">
                                                        <div class="app-company-logo">
                                                            <img class="app-company-logo-img" src="assets/images/company_logo.png" alt="">
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                            <div class="col-md-11 accountant-details">
                                                <p>{{ accountant.accountantDetails }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 fan-club-url-profile-pic p-0">
                                        <ng-container *ngIf="accountant?.profile_image; else appImage">
                                            <div class="app-avatar-wrapper">
                                                <div class="app-avatar">
                                                    <img class="app-avatar-img" src="{{ accountant?.profile_image }}" alt="">
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #appImage>
                                            <div class="app-avatar-wrapper">
                                                <div class="app-avatar">
                                                    <img class="app-avatar-img app-avatar-img-default" src="assets/images/default_profile.png" alt="">
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center paginate">
                                <pagination-controls [maxSize]="5"  [responsive]="true" previousLabel="" nextLabel="" (pageChange)="changePage($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 pl-md-0">
                        <div class="row">
                            <app-fan-club-member-widget></app-fan-club-member-widget>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
