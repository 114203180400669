import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '@env/environment';
import { State, StatesService } from '@app/services/states.service';
import { ServiceProvidedService, Service } from '@app/services/service-provided.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Industry, IndustriesService } from '@app/services/industries.service';
import { AccountantService } from '@app/services/accountant.service';
import { Accountant } from '@app/interfaces/accountant';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  companyName: string = environment.companyName;
  states: State[] = [];
  services: Service[] = [];
  industries: Industry[] = [];
  statesLoading = false;
  servicesLoading = false;
  industriesLoading = false;
  selectedState: string;
  selectedService = [];
  selectedIndustry = [];
  itemsPerPage = 10;
  currentPage = 0;
  totalAccountants = 0;
  accountants: Accountant[] = [];
  searchAccountant = '';
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private stateService: StatesService,
    private serviceService: ServiceProvidedService,
    private accountantService: AccountantService,
    private industriesService: IndustriesService,
    private logger: NGXLogger
  ) {
    this.activatedRoute.queryParams
      .pipe(
        filter((queryParams) => !!queryParams?.search),
        takeUntil(this.componentDestroyed$))
      .subscribe(queryParams => {
        this.searchAccountant = queryParams.search;
    });
  }

  ngOnInit(): void {
    this.loadStates();
    this.loadServices();
    this.loadIndustries();
    this.loadAccountants(true);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  private loadStates() {
    this.statesLoading = true;
    this.stateService.getState()
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(x => {
        this.states = x;
        this.statesLoading = false;
      });
  }

  private loadServices() {
    this.servicesLoading = true;
    this.serviceService.getServices()
      .pipe(
        map(x => x.filter(y => !y.disabled)),
        takeUntil(this.componentDestroyed$))
      .subscribe(x => {
        this.services = x;
        this.servicesLoading = false;
      });
  }

  private loadIndustries() {
    this.industriesLoading = true;
    this.industriesService.getIndustries()
      .pipe(
        map(x => x.filter(y => !y.disabled)),
        takeUntil(this.componentDestroyed$))
      .subscribe(x => {
        this.industries = x;
        this.industriesLoading = false;
      });
  }

  searchState(term: string, state: State): any {
    term = term.toLowerCase();
    return state.state.toLowerCase().indexOf(term) > -1 || state.abbreviation.toLowerCase() === term;
  }

  loadAccountants(showLoader: boolean): void {
    this.accountants = [];
    if (showLoader) {
      this.ngxService.start();
    }
    let start = 0;
    if (this.currentPage) {
      start = (this.currentPage - 1) * this.itemsPerPage;
    }
    const limit = this.itemsPerPage;
    this.accountantService.getAccountantsList(
      start,
      limit,
      this.selectedState,
      this.selectedService,
      this.selectedIndustry,
      this.searchAccountant
    ).then(response => {
      const accountantsText = 'accountants';
      const totalText = 'total';
      this.accountants = response[accountantsText];
      this.totalAccountants = response[totalText];
      this.ngxService.stop();
      this.ngxService.stopBackground();
    }).catch(error => {
      this.logger.error('ERROR: -> HomeComponent -> loadAccountants -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
    });
  }

  addFilter(): void {
    this.currentPage = 0;
    this.loadAccountants(true);
  }

  changePerPageOption(): void {
    this.currentPage = 0;
    this.loadAccountants(true);
  }

  changePage(currentPage: number): void {
    this.currentPage = currentPage;
    this.loadAccountants(true);
  }

  onSearch(event: any): void {
    if (event.type === 'keyup') {
      if (event.key === 'Enter' || (event.key === 'Backspace' && this.searchAccountant === '')) {
        this.currentPage = 0;
        this.loadAccountants(false);
      }
    }
  }
}
