import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private httpClient: HttpClient
  ) { }

  post(url: string, data: any): Promise<any> {
    return this.httpClient.post(url, data).toPromise();
  }

  get(url: string): Promise<any> {
    return this.httpClient.get(url).toPromise();
  }

  getById(url: string, id: string): Promise<any> {
    return this.httpClient.get(url + '/' + id).toPromise();
  }

  updateById(url: string, id: string, data: any): Promise<any> {
    return this.httpClient.put(url + '/' + id, data).toPromise();
  }

  deleteById(url: string, id: string): Promise<any> {
    return this.httpClient.delete(url + '/' + id).toPromise();
  }

  getByParams(url: string, params: any[]): Promise<any> {
    let paramString = '';
    for (const param of params) {
      paramString += '/' + param;
    }
    return this.httpClient.get(url + paramString).toPromise();
  }

  postByParams(url: string, params: any[], data: any): Promise<any> {
    let paramString = '';
    for (const param of params) {
      paramString += '/' + param;
    }
    return this.httpClient.post(url + paramString, data).toPromise();
  }

  updateByParams(url: string, id: string, params: any[], data: any): Promise<any> {
    let paramString = '';
    for (const param of params) {
      paramString += '/' + param;
    }
    return this.httpClient.put(url + id + paramString, data).toPromise();
  }

  getByQueryParams(url: string, queryParams: object): Promise<any> {
    let params = new HttpParams();
    for (const [key, value] of Object.entries(queryParams)) {
      params = params.append(key, value);
    }
    return this.httpClient.get(url, { params }).toPromise();
  }
}
