<div class="fan-club-container">
    <h4 class="text-center">Fan Club Members</h4>

    <div class="fan-club-member mt-4" *ngFor="let member of fanClubMembers">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4 fan-club-url-profile-pic p-0">
                        <ng-container *ngIf="member?.profile_image; else appImage">
                            <div class="app-avatar-wrapper">
                                <div class="app-avatar">
                                    <img class="app-avatar-img" src="{{ member?.profile_image }}" alt="">
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #appImage>
                            <div class="app-avatar-wrapper">
                                <div class="app-avatar">
                                    <img class="app-avatar-img app-avatar-img-default" src="assets/images/default_profile.png" alt="">
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-md-7 pr-0 pt-1">
                        <h5 class="card-title text-capitalize"><a class="card-link" [routerLink]="['/profile', member.rockStarProfileUrlName]">{{ member.fullName }}</a>
                            <span class="card-subtitle text-capitalize">&nbsp; {{ member.jobTitle }}</span>
                        </h5>
                        <div class="rating" *ngIf="member.noOfReviews != 0">
                            {{ member.avgOfReviews }} &nbsp;<bar-rating [readOnly]="true" [(rate)]="member.avgOfReviews" [max]="5"></bar-rating>
                        </div>
                        <div class="rating-count" *ngIf="member.noOfReviews != 0">
                            {{ member.noOfReviews }} review(s)
                        </div>
                        <p class="card-text text-capitalize company">{{ member.company }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
