import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { ReviewService } from '@app/services/review.service';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-write-a-review',
  templateUrl: './write-a-review.component.html',
  styleUrls: ['./write-a-review.component.css']
})
export class WriteAReviewComponent implements OnInit, OnDestroy {

  title = `Write a Review |  ${environment.companyName}`;
  token: string;
  accountantId: string;
  accountantName: string;
  accountantEmail: string;
  reviewerEmail: string;
  reviewerName: string;
  message: string;
  rating = 5;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private logger: NGXLogger,
    private titleService: Title,
    private reviewService: ReviewService
  ) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.activatedRoute.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.accountantName = data.tokenDetails.accountantName;
        this.accountantEmail = data.tokenDetails.accountantEmail;
        this.accountantId = data.tokenDetails.accountantId;
        this.reviewerEmail = data.tokenDetails.reviewerEmail;
        this.titleService.setTitle(this.title);
    });
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params?.token) {
          this.token = params.token;
        }
        this.ngxService.stop();
        this.ngxService.stopBackground();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  saveReview(): void {
    this.ngxService.start();
    return this.reviewService.saveReview(
      this.token,
      this.reviewerEmail,
      this.reviewerName,
      this.rating,
      this.message
    ).then(() => {
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.router.navigate(['/thank-you']);
    }).catch(error => {
      this.logger.error('ERROR: -> WriteAReviewComponent -> saveReview -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
    });
  }
}
