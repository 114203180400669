import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent {
  loginURL: string = environment.LOGIN_URL;
}
