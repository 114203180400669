import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FanClubMemberService } from '@app/services/fan-club-member.service';
import { FanClubMember } from '@app/interfaces/fan-club-member';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-fan-club-member-widget',
  templateUrl: './fan-club-member-widget.component.html',
  styleUrls: ['./fan-club-member-widget.component.css']
})
export class FanClubMemberWidgetComponent implements OnInit {
  fanClubMembers: FanClubMember[] = [];
  constructor(
    private ngxService: NgxUiLoaderService,
    private fanClubMemberService: FanClubMemberService,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.fanClubMemberService.getFanClubMembers().then(response => {
      this.ngxService.stop();
      this.ngxService.stopBackground();
      const members = 'members';
      this.fanClubMembers = response[members];
    }).catch(error => {
      this.logger.error('ERROR: -> FanClubMemberWidgetComponent -> ngOnInit -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
    });
  }
}
