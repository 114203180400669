import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Marker } from '@app/interfaces/marker';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '@env/environment';
import { MapService } from '@app/services/map.service';
import { NGXLogger } from 'ngx-logger';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Observable, of, Subject } from 'rxjs';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { catchError, map, takeUntil } from 'rxjs/operators';

declare const google: any;
@Component({
  selector: 'app-accountants-map',
  templateUrl: './accountants-map.component.html',
  styleUrls: ['./accountants-map.component.css']
})
export class AccountantsMapComponent implements OnInit, OnDestroy {
  previousIW = null;
  googleMap: google.maps.Map;
  zoom = environment.GOOGLE_MAP.ZOOM;
  lat = environment.GOOGLE_MAP.CENTER_LAT;
  lng = environment.GOOGLE_MAP.CENTER_LNG;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  googleMapOptions: google.maps.MapOptions;
  isGoogleApiReady$: Observable<boolean>;
  mapStyle = [{
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [{
      color: '#444444'
    }]
  }, {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [{
      color: '#f2f2f2'
    }]
  }, {
    featureType: 'poi',
    elementType: 'all',
    stylers: [{
      visibility: 'off'
    }]
  }, {
    featureType: 'road',
    elementType: 'all',
    stylers: [{
      saturation: -100
    }, {
      lightness: 45
    }]
  }, {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [{
      visibility: 'simplified'
    }]
  }, {
    featureType: 'road.arterial',
     elementType: 'labels.icon',
      stylers: [{
        visibility: 'off'
      }]
    }, {
      featureType: 'transit',
      elementType: 'all',
      stylers: [{
        visibility: 'off'
      }]
    }, {
      featureType: 'water',
      elementType: 'all',
      stylers: [{
        color: '#46bcec'
      }, {
        visibility: 'on'
      }]
    }];
  markers: Marker[] = [];
  faLocationArrow = faLocationArrow;
  mapRestriction: google.maps.MapRestriction;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private ngxService: NgxUiLoaderService,
    private mapService: MapService,
    private logger: NGXLogger
  ) {
    const maxLat = Math.atan(Math.sinh(Math.PI)) * 180 / Math.PI;
    this.mapRestriction = {
      latLngBounds: {
        north: maxLat,
        south: -maxLat,
        west: -180,
        east: 180
      },
      strictBounds: true
    };
  }

  ngOnInit(): void {
    this.ngxService.start();
    this.googleMapOptions = {
      center: {
        lat: this.lat,
        lng: this.lng,
      },
      zoom: this.zoom,
      styles: this.mapStyle,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      scrollwheel: false,
      mapTypeId: 'roadmap',
      disableDefaultUI: false,
      zoomControl: true,
      minZoom: 4,
      restriction: this.mapRestriction
    };
    this.isGoogleApiReady$ = this.mapService.getGoogleMapsApi()
      .pipe(
        map(() => true),
        catchError((error) => {
          this.logger.error('ERROR: -> AccountantsMapComponent -> OnInit -> getGoogleMapsApi -> error', error);
          return of(false);
        }),
        takeUntil(this.componentDestroyed$)
      );
    this.mapService.getAccountantsToPlot().then(response => {
      this.ngxService.stop();
      this.ngxService.stopBackground();
      const accountants = 'accountants';
      this.markers = response[accountants].map((accountant) => (
        {
          position: {
            lat: accountant.lat,
            lng: accountant.lng,
          },
          draggable: accountant.draggable,
          rockStarProfileUrlName: accountant.rockStarProfileUrlName,
          fullName: accountant.fullName,
          // eslint-disable-next-line camelcase
          profile_image: accountant.profile_image,
          address: accountant.address,
          hideUserEmail: accountant.hideUserEmail,
          email: accountant.email,
          hideUserPhoneNumber: accountant.hideUserPhoneNumber,
          phoneNumber: accountant.phoneNumber,
          website: accountant.website
        }
      ));
    }).catch(error => {
      this.logger.error('ERROR: -> AccountantsMapComponent -> ngOnInit -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  mapReady(event: google.maps.Map): void {
    this.googleMap = event;
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('myLocation'));
  }

  openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow): void {
    if (this.previousIW) {
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
    infoWindow.open(marker);
  }

 setCurrentLocation(): void {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const myLocation = new google.maps.Marker({
            clickable: false,
            icon: new google.maps.MarkerImage('//maps.gstatic.com/mapfiles/mobile/mobileimgs2.png',
                    new google.maps.Size(22, 22),
                    new google.maps.Point(0, 18),
                    new google.maps.Point(11, 11)),
            shadow: null,
            zIndex: 999,
            map: this.googleMap
        });
        const me = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        myLocation.setPosition(me);
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(me);
        this.googleMap.fitBounds(bounds);
        const zoom = this.googleMap.getZoom();
        this.googleMap.setZoom(zoom > this.zoom ? this.zoom : zoom);
      }, (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          this.googleMap.setCenter(new google.maps.LatLng( this.lat, this.lng ) );
          const zoom = this.googleMap.getZoom();
          this.googleMap.setZoom(zoom > this.zoom ? this.zoom : zoom);
        }
      });
    }
  }

  appLog(value: any): void {
    this.logger.info('INFO: -> AccountantsMapComponent -> appLog -> value', value);
  }
}
