import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { faTwitter, faFacebook, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  currentDate: Date = new Date();
  companyName: string = environment.companyName;
  helpLineNumber: string = environment.helpLineNumber;
  inquireNumber: string = environment.inquireNumber;
  linkedInURL: string = environment.linkedInURL;
  youtubeURL: string = environment.youtubeURL;
  twitterURL: string = environment.twitterURL;
  facebookURL: string = environment.facebookURL;
  termOfServiceURL: string = environment.termOfServiceURL;
  privacyPolicyURL: string = environment.privacyPolicyURL;
  supportPortalURL: string = environment.supportPortalURL;
  accountingSuiteAcademyLink: string = environment.accountingSuiteAcademyLink;
  userGuideLink: string = environment.userGuideLink;
  integrationsLink: string = environment.integrationsLink;
  getStartedLink: string = environment.getStartedLink;
  blogURL: string = environment.blogURL;
  aboutContactLink: string = environment.aboutContactLink;
  developerLink: string = environment.DEVELOPER_URL;
  accountantAndBookkeeperLink: string = environment.accountantAndBookkeeperLink;
  productEnhancementsLink: string = environment.productEnhancementsLink;
  whyCustomBooksLink: string = environment.whyCustomBooksLink;
  projectAndTimeTrackingLink: string = environment.projectAndTimeTrackingLink;
  inventoryManagementLink: string = environment.inventoryManagementLink;
  purchasingLink: string = environment.purchasingLink;
  salesLink: string = environment.salesLink;
  accountingReportingLink: string = environment.accountingReportingLink;
  overviewLink: string = environment.overviewLink;
  pricingLink: string = environment.pricingLink;
  careerLink: string = environment.careerLink;

  faTwitter = faTwitter;
  faFacebook = faFacebook;
  faYoutube = faYoutube;
  faLinkedin = faLinkedin;
}
