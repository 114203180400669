import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EMPTY, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenResolverService {

  constructor(
    private router: Router,
    private authService: AuthService,
    private ngxService: NgxUiLoaderService,
    private logger: NGXLogger
  ) { }

  resolve(): Promise<object> | Observable<never> {
    this.ngxService.start();
    this.authService.setToken('');
    return this.authService.generateToken().then((response: any) => {
      this.authService.setToken(response.token);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      return response;
    }).catch(error => {
      this.logger.error('ERROR: -> TokenResolverService -> this.authService.generateToken -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.router.navigate(['not-found']);
      return EMPTY;
    });
  }
}
