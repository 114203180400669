import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { HeaderComponent } from '@app/components/header/header.component';
import { FooterComponent } from '@app/components/footer/footer.component';
import { HomeComponent } from '@app/components/home/home.component';
import { AccountantsMapComponent } from '@app/components/accountants-map/accountants-map.component';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { BarRatingModule } from 'ngx-bar-rating';
import { NgxPaginationModule } from 'ngx-pagination';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '@env/environment';
import { BasicAuthInterceptor } from '@app/helpers/basic-auth.interceptor';
import { AccountantDetailWidgetComponent } from '@app/components/accountant-detail-widget/accountant-detail-widget.component';
import { FanClubMemberWidgetComponent } from '@app/components/fan-club-member-widget/fan-club-member-widget.component';
import { ImageComponent } from '@app/components/image/image.component';
import { ErrorComponent } from '@app/components/error/error.component';
import { SuccessComponent } from '@app/components/success/success.component';
import { NotFoundComponent } from '@app/components/not-found/not-found.component';
import { WriteAReviewComponent } from '@app/components/write-a-review/write-a-review.component';
import { ProfileComponent } from '@app/components/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReplacePipe } from '@app/helpers/replace.pipe';
import { AddressMapComponent } from './components/address-map/address-map.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { GoogleMapsModule } from '@angular/google-maps';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#fecb00',
  pbColor: '#fecb00',
};
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AccountantsMapComponent,
    AccountantDetailWidgetComponent,
    FanClubMemberWidgetComponent,
    ImageComponent,
    ErrorComponent,
    SuccessComponent,
    NotFoundComponent,
    WriteAReviewComponent,
    ProfileComponent,
    ReplacePipe,
    AddressMapComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientJsonpModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot(),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: environment.disableConsoleLogging,
      serverLoggingUrl: '/frontEndLogs',
      serverLogLevel: NgxLoggerLevel.ERROR,
      enableSourceMaps: !environment.disableConsoleLogging
    }),
    FontAwesomeModule,
    NgSelectModule,
    BarRatingModule,
    NgxPaginationModule,
    GoogleMapsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    Title,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.GOOGLE_CAPTCHA_SITE_KEY
      } as RecaptchaSettings,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
