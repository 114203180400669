export const environment = {
  // --------Fixed-----------------------//
  production: true,
  companyName: 'CustomBooks™',
  companyURL: 'https://www.custombooks.com',
  supportURL: 'https://support.custombooks.com',
  supportPortalURL: 'https://help.custombooks.com/portal/home',
  blogURL: 'https://www.custombooks.com/company/blog-posts',
  currency: 'USD',
  helpLineNumber: '888.328.8275',
  inquireNumber: '415.462.5477',
  acsFranchiseeCode: '000',
  COMMON_ERROR_MESSAGE: 'Sorry something went wrong. Please try again later. ',
  GOOGLE_CAPTCHA_SITE_KEY: '6Lc7fB0UAAAAACCnGntjF7SD47HtX9Bk0O2QatGm',

  whyCustomBooksLink: 'https://www.custombooks.com/company/why-us',

  businessLink: 'https://www.custombooks.com/partner-solutions/accounting-webinars',
  overviewLink: 'https://www.custombooks.com/features/all-features',
  findAnAdvisorLink: 'https://rockstar.custombooks.com',
  accountingReportingLink: 'https://www.custombooks.com/features/accounting-reporting',
  salesLink: 'https://www.custombooks.com/features/sales-purchases',
  purchasingLink: 'https://www.custombooks.com/features/sales-purchases',
  projectAndTimeTrackingLink: 'https://www.custombooks.com/features/project-time-tracking',
  inventoryManagementLink: 'https://www.custombooks.com/features/inventory-management',

  partnersLink: 'https://www.custombooks.com/partner-solutions/accounting-webinars',
  accountantAndBookkeeperLink: 'https://www.custombooks.com/partner-solutions/partner-solutions',
  webinarsLink: 'https://www.custombooks.com/partner-solutions/accounting-webinars',
  integrationsLink: 'https://www.custombooks.com/integration-extensions/accountingsuite-store',

  getStartedLink: 'https://www.custombooks.com/free-trial-sign-up',
  userGuideLink: 'https://help.custombooks.com',
  accountingWebinarsLink: 'https://www.custombooks.com/partner-solutions/accounting-webinars',
  bookkeepingWebinarsLink: 'https://www.custombooks.com/partner-solutions/accounting-webinars',
  productEnhancementsLink: 'https://www.custombooks.com/features/all-features',
  accountingSuiteAcademyLink: 'https://www.custombooks.com/academy/custombooks-academy',
  accountingSuiteStatusLink: 'https://status.custombooks.com',
  aboutContactLink: 'https://www.custombooks.com/support/contact-us',
  careerLink: 'https://yellow-labs-software-inc.breezy.hr/',
  pricingLink: 'https://www.custombooks.com/plans-pricing',

  termOfServiceURL: 'https://custombooks.com/company/end-user-agreement',
  privacyPolicyURL: 'https://custombooks.com/privacy-policy',

  linkedInURL: 'https://www.linkedin.com/company/custombookserp',
  youtubeURL: 'https://www.youtube.com/@CustomBooksERP',
  twitterURL: 'https://x.com/custombookserp',
  facebookURL: 'https://www.facebook.com/CustomBooksERP',

  // --------Change based on environment-----------------------//
  LOGIN_URL: 'https://dev.custombooks.com',
  PAY_URL: 'https://paydev.custombooks.com',
  INTCBK_URL: 'https://intcbkdev.custombooks.com',
  CAT_URL: 'https://catdev.custombooks.com',
  ADD_CARD_URL: 'https://addcarddev.custombooks.com',
  REGISTER_URL: 'https://registerdev.custombooks.com',
  ROCKSTAR_URL: 'https://rockstardev.custombooks.com',
  OAUTH_URL: 'https://oauthdev.custombooks.com',
  DEVELOPER_URL: 'https://apidev.custombooks.com',

  CBK_LOGO_IMAGE: 'logo-dev.png',
  GOOGLE_MAP: {
    KEY: 'AIzaSyBQIC3GXwrh-gv0Ei-lSdgPtLT6nqoPqCI',
    CENTER_LAT: 41.70572869954652,
    CENTER_LNG: -99.40429725000001,
    ZOOM: 4
  },
  disableConsoleLogging: false
  // --------Change based on environment-----------------------//
};
