import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { faPhone, faGlobe, faMapMarkerAlt, faEnvelope, faBusinessTime, faClock } from '@fortawesome/free-solid-svg-icons';

import { environment } from '@env/environment';
import { Accountant } from '@app/interfaces/accountant';
import { ApiService } from '@app/services/api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {
  accountantDetails: Accountant;
  sendMessageForm: FormGroup;

  captchaCode: string;
  captchaSettingLang = 'en';
  captchaSettingTheme: 'dark' | 'light' = 'light';
  captchaSettingType: 'image' | 'audio' = 'image';
  captchaSettingGlobalDomain = false;
  captchaSiteKey: string = environment.GOOGLE_CAPTCHA_SITE_KEY;

  title: string;
  faMapMarkerAlt = faMapMarkerAlt;
  faEnvelope = faEnvelope;
  faBusinessTime = faBusinessTime;
  faClock = faClock;
  faPhone = faPhone;
  faGlobe = faGlobe;
  companyName: string = environment.companyName;
  messageForm = false;
  lastSearched = '';
  hasColumnsDetails: boolean = false;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private api: ApiService,
    private titleService: Title,
  ) {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(queryParams => {
        if (queryParams && queryParams.search) {
          this.lastSearched = queryParams.search;
        }
    });
  }

  ngOnInit(): void {
    this.ngxService.start();
    this.activatedRoute.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.accountantDetails = data.accountantDetails;
        this.hasColumnsDetails = !!this.accountantDetails.industriesServed ||
          !!this.accountantDetails.servicesProvided ||
          !!this.accountantDetails.professionalDesignations ||
          !!this.accountantDetails.knownLanguages;
        this.title = `${data.accountantDetails.fullName} | ${environment.companyName}`;
        this.titleService.setTitle(this.title);
        this.ngxService.stop();
        this.ngxService.stopBackground();
    });
  }

  trackByFn(index: any, item: any): any {
    return item.id;
  }

  onSendMessage(value: any): void {
    value.id = this.accountantDetails.id;
    if (value.reCaptcha && typeof value.reCaptcha === 'string' && value.reCaptcha.trim() !== '') {
      this.api.post('/send_accountant_message', value).then((response: any) => {
        if (response) {
          this.toastr.success(response.message, response.title);
          this.messageForm = false;
        }
      }).catch((error: any) => {
        this.toastr.error(error?.error?.code, error?.error?.message);
      });
    } else {
      this.toastr.error('Google captcha is required!', 'Please verify the google captcha.');
    }
  }

  onCreateMsgForm(): void {
    if (this.messageForm) {
      this.messageForm = false;
    } else {
      this.messageForm = true;
      this.sendMessageForm = new FormGroup({
        message   : new FormControl('', [Validators.required]),
        email     : new FormControl('', [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]),
        phone     : new FormControl('',
          [Validators.pattern(
            '(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{2,4})')]),
        reCaptcha : new FormControl(['', Validators.required])
      });
    }
  }

  handleCaptchaSuccess($event: any): void {
    this.captchaCode = $event;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}
