<div class="container home-body">
    <div class="row justify-content-md-center">
      <div class="col col-lg-6 div-border">
        <div class='text-center error-box'>
            <img class="image-check" src='assets/images/white-error.png' alt="error">
            <div class='text-center heading-box'>{{ heading }}</div>
        </div>
        <div class="justify-content-center">
            <div class="input-group mb-3 mt-3" [innerHTML]="mainContent">
            </div>
            <div class="input-group mb-3" [innerHTML]="message"></div>
            <div [hidden]="!error" class="mb-3">
                <a class="" data-toggle="collapse" href="#technicalDetails" role="button" aria-expanded="false" aria-controls="collapseExample">
                    Click here for Technical Details
                </a>
                <div class="collapse" id="technicalDetails">
                    <div class="card card-body">
                        <div><strong>Error Code:</strong> {{error}} </div>
                        <div><strong>Description:</strong> {{ errorDescription }}</div>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                If it won't connect, try reporting ERROR to {{ companyName }} support, Please visit &nbsp;<a href='{{ supportPortalURL }}'>{{ companyName }} support</a>.
            </div>
            <div class="input-group mb-3">
                We value your business. Learn more about {{ companyName }} and how they help small business:&nbsp; <a href='{{ blogURL }}'>click here</a>
            </div>
            <div class="input-group mb-3 justify-content-md-center">
                <a type="button" class="btn close-btn" onclick='window.close();'>Close Tab</a>
            </div>
        </div>
      </div>
    </div>
</div>
