import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ReviewService } from './review.service';
import { Observable, EMPTY } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class WriteAReviewResolverService {
  token: string;
  constructor(
    private router: Router,
    private reviewService: ReviewService,
    private authService: AuthService,
    private ngxService: NgxUiLoaderService,
    private logger: NGXLogger
  ) { }

  resolve(route: ActivatedRouteSnapshot): Promise<object> | Observable<never> {
    this.ngxService.start();
    this.token = route.queryParams.token;
    this.authService.setToken('');
    return this.authService.generateToken().then((tokenResponse: any) => {
      this.authService.setToken(tokenResponse.token);
      return this.reviewService.getTokenDetails(this.token).then((response: any) => {
        this.ngxService.stop();
        this.ngxService.stopBackground();
        const tokenDetails = {
          accountantId: response?.accountantId,
          accountantName: response?.accountantName,
          accountantEmail: response?.accountantEmail,
          reviewerEmail: response?.reviewerEmail,
        };
        return tokenDetails;
      }).catch(error => {
        this.logger.error('ERROR: -> WriteAReviewResolverService -> error', error);
        this.ngxService.stop();
        this.ngxService.stopBackground();
        this.router.navigate(['/invalid-link']);
        return EMPTY;
      });
    }).catch(error => {
      this.logger.error('ERROR: -> TokenResolverService -> this.authService.generateToken -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.router.navigate(['invalid-link']);
      return EMPTY;
    });
  }
}
