import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token: Observable<string>;
  private tokenSubject: BehaviorSubject<string>;
  constructor(
    private httpClient: HttpClient
  ) {
    this.tokenSubject = new BehaviorSubject<string>(localStorage.getItem('token'));
    this.token = this.tokenSubject.asObservable();
  }

  public getToken(): any {
    return this.tokenSubject.value;
  }

  setToken(token: string): void {
    if (token) {
      localStorage.setItem('token', token);
      this.tokenSubject.next(token);
    }
  }

  generateToken(): Promise<any> {
    return this.httpClient.get<any>('/token')
    .pipe(map((tokenDetails: any) => {
      if (tokenDetails?.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('token', tokenDetails.token);
          this.tokenSubject.next(tokenDetails.token);
      }
      return tokenDetails.token;
    })).toPromise();
  }
}
